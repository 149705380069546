var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.show)?_c('b-form',{on:{"change":_vm.onChange,"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)},"reset":function($event){$event.preventDefault();return _vm.onReset.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"8","lg":"9"}},[_c('b-form-group',{attrs:{"id":"input-group-message","label":"Message","label-for":"input-message"}},[_c('b-form-textarea',{attrs:{"id":"input-message","placeholder":"","rows":"5","max-rows":"7","maxlength":_vm.telegramBotApiMaxMessageLength},model:{value:(_vm.formData.message),callback:function ($$v) {_vm.$set(_vm.formData, "message", $$v)},expression:"formData.message"}})],1),_c('p',{staticClass:"small",class:{
            'alert-warning': _vm.shouldWarnForLongMessage,
            'alert-danger': _vm.shouldShowDangerForLongMessage,
          }},[_vm._v(" "+_vm._s(_vm.formData.message.length)+" from "+_vm._s(_vm.telegramBotApiMaxMessageLength)+" characters ")]),_c('p',[_c('a',{attrs:{"href":"https://core.telegram.org/bots/api#markdown-style","target":"_blank","rel":"noopener"}},[_vm._v(" API uses Markdown Style")])]),_c('b-alert',{attrs:{"show":"","variant":"warning"}},[_vm._v(" Watch out for escape characters such as "),_c('i',[_vm._v("_ * \\")]),_vm._v(". Not escaping them with a backslash \"\\\" may fail the messaging. ")]),_c('b-form-group',{attrs:{"id":"input-group-subscriber-filter","label":"Subscriber Filter","label-for":"input-subscriber-filter"}},[_c('b-form-select',{attrs:{"id":"input-subscriber-filter","options":_vm.subscriberFilters,"required":""},model:{value:(_vm.formData.subscriberFilter),callback:function ($$v) {_vm.$set(_vm.formData, "subscriberFilter", $$v)},expression:"formData.subscriberFilter"}})],1),(
            _vm.formData.subscriberFilter ===
            _vm.SUBSCRIBER_FILTERS_ENUM.SINGLE_SUBSCRIBER
          )?_c('div',[_c('b-form-group',{attrs:{"id":"input-group-telegram-user-id","label":"Telegram User Id","label-for":"input-telegram-user-id"}},[_c('b-form-input',{attrs:{"id":"input-telegram-user-id","type":"number","step":"1","required":""},model:{value:(_vm.formData.subscriberFilterPayload.telegramUserId),callback:function ($$v) {_vm.$set(_vm.formData.subscriberFilterPayload, "telegramUserId", $$v)},expression:"formData.subscriberFilterPayload.telegramUserId"}})],1)],1):(
            _vm.formData.subscriberFilter ===
            _vm.SUBSCRIBER_FILTERS_ENUM.SPECIFIC_ALERT_SUBSCRIBERS
          )?_c('div',[_c('b-form-group',{attrs:{"id":"input-group-specific-alert","label":"Alert","label-for":"input-specific-alert"}},[_c('b-form-select',{attrs:{"id":"input-specific-alert","options":_vm.telegramBotAlerts,"required":""},model:{value:(_vm.formData.subscriberFilterPayload.alert),callback:function ($$v) {_vm.$set(_vm.formData.subscriberFilterPayload, "alert", $$v)},expression:"formData.subscriberFilterPayload.alert"}})],1)],1):_vm._e(),_c('b-form-group',{attrs:{"id":"input-group-show-upvote-button","label":"Show upvote button","label-for":"input-show-upvote-button"}},[_c('b-form-checkbox',{attrs:{"id":"input-show-upvote-button","type":"checkbox"},model:{value:(_vm.formData.showUpvoteButton),callback:function ($$v) {_vm.$set(_vm.formData, "showUpvoteButton", $$v)},expression:"formData.showUpvoteButton"}})],1),_c('b-form-group',{attrs:{"id":"input-group-publish-to-env-bot","label":'Really publish to ' +
            _vm.envToText +
            ' bot subscribers? Otherwise publish to all DEV channel for testing',"label-for":"input-publish-to-env-bot"}},[_c('b-form-checkbox',{attrs:{"id":"input-publish-to-prod-bot","type":"checkbox"},model:{value:(_vm.formData.publishToProdBot),callback:function ($$v) {_vm.$set(_vm.formData, "publishToProdBot", $$v)},expression:"formData.publishToProdBot"}}),_vm._v(" Yes, publish to "+_vm._s(_vm.envToText)+" bot. ")],1)],1),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"text-center"},[(_vm.isSubmitting)?_c('div',[_c('b-spinner',{attrs:{"label":"Submitting..."}})],1):_c('b-button-group',{staticClass:"mt-2 mb-2"},[_c('b-button',{attrs:{"type":"submit","variant":"outline-success","disabled":_vm.isFormControlsDisabled}},[_c('b-icon-file-check'),_vm._v(" Publish ")],1),_c('b-button',{attrs:{"type":"reset","variant":"outline-secondary","disabled":_vm.isFormControlsDisabled}},[_c('b-icon-arrow-counterclockwise'),_vm._v(" Reset ")],1)],1)],1)],1)],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }