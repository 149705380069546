
import { Component, Vue } from "vue-property-decorator";

const appUrl = process.env.VUE_APP_URL;

@Component
export default class Home extends Vue {
  userDisabledAdminUserTracking(): void {
    if (!appUrl) {
      throw new Error(
        `Cannot redirect client to set admin cookie URL, because APP_URL environment variable not set – aborting. [appUrl=${appUrl}]`
      );
    }
    // Go to app, set cookie, return to admin. If that cookie is present in app client, no beyondp2p analytics
    // tracking will be send to servers.
    location.assign(`${appUrl}/api/analytics/set-admin-cookie`);
  }
}
